import ApiService from '@/services/core/api.service'

const OrganizationService = {
  async get (query) {
    return ApiService.get(`user/organizations?${query}`)
  },

  async post (payload) {
    return ApiService.post('user/organizations', payload)
  },

  async put (payload) {
    return ApiService.put(`user/organizations/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`user/organizations/${payload.id}`)
  }
}

export default OrganizationService
