function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/user/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const userRoutes = [
  {
    path: '/user/dashboard',
    component: view('Dashboard'),
    name: 'user.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/maintenance/employees',
    component: view('Employees'),
    name: 'user.employees',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Employees',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/maintenance/companies',
    component: view('Companies'),
    name: 'user.companies',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Companies',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/maintenance/organizations',
    component: view('Organizations'),
    name: 'user.organizations',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Organizations',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/maintenance/positions',
    component: view('Positions'),
    name: 'user.positions',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Positions',
          active: true
        }
      ]
    }
  }
]

export default userRoutes
