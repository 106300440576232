import ApiService from '@/services/core/api.service'

const ListService = {
  async getCompanies () {
    return ApiService.get('shared/list/companies')
  },

  async getOrganizations (companyId) {
    return ApiService.get(`shared/list/organizations?company_id=${companyId}`)
  },

  async getPositions (companyId) {
    return ApiService.get(`shared/list/positions?company_id=${companyId}`)
  }
}

export default ListService
